import { render, staticRenderFns } from "./ModalDeRegulamento.vue?vue&type=template&id=b5bcaca6&scoped=true"
import script from "./ModalDeRegulamento.vue?vue&type=script&lang=js"
export * from "./ModalDeRegulamento.vue?vue&type=script&lang=js"
import style0 from "./ModalDeRegulamento.vue?vue&type=style&index=0&id=b5bcaca6&prod&lang=scss&scoped=true"
import style1 from "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css?vue&type=style&index=1&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5bcaca6",
  null
  
)

export default component.exports